import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { COLORS, WithCopyButton } from "@stacklet/ui";
import { graphql, useFragment } from "react-relay";

import DetailsMetaData from "app/components/DetailsMetaData";
import DetailsGridItem from "app/components/grid/DetailsGridItem";
import { HumanTimestamp } from "app/components/HumanTimestamp";
import { ProviderIcon } from "app/components/icons/ProviderIcon";

import { NoSecurityContext } from "../../components/NoSecurityContext";

import type { AccountDetailsMeta_account$key } from "./__generated__/AccountDetailsMeta_account.graphql";

interface Props {
  account: AccountDetailsMeta_account$key;
}

const Pre = styled("pre")(() => ({ margin: 0 }));

export function AccountDetailsMeta({ account }: Props) {
  const data = useFragment(
    graphql`
      fragment AccountDetailsMeta_account on Account {
        discovery {
          __typename
        }
        email
        key
        tags {
          key
          value
        }
        name
        path
        provider
        securityContext
        validated_at
        credentialInfo {
          ... on AzureCredentialInfo {
            tenantID
            clientID
          }
          ... on TencentCloudCredentialInfo {
            keyID
          }
        }
      }
    `,
    account,
  );

  let securityContextTitle;
  switch (data.provider) {
    case "Azure":
      securityContextTitle = "Azure service principal secret";
      break;
    case "GCP":
      securityContextTitle = "GCP Service Account JSON Credentials";
      break;
    case "TencentCloud":
      securityContextTitle = "Tencent CAM Secret";
      break;
    default:
      securityContextTitle = "Security Context";
  }

  const tags = data && data.tags;
  const tagsLabel = tags?.map((tag) => `${tag.key}: ${tag.value}`);

  const formattedAzureSecurityContext = `{\n"clientId": ${data.credentialInfo?.clientID},\n"tenantId": ${data.credentialInfo?.tenantID}\n}`;

  const formattedTencentCloudSecurityContext = `{"keyId": ${data.credentialInfo?.keyID}}`;

  return (
    <DetailsMetaData>
      <Grid alignItems="center" columnGap={18} sx={{ pb: 4 }} container>
        <Grid item>
          <Box display="flex">
            {data.provider ? <ProviderIcon provider={data.provider} /> : null}
            <Box alignItems="center" display="flex" ml={2}>
              {data.name}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        columnGap={8}
        direction="row"
        sx={{ pb: 4 }}
        container
      >
        <DetailsGridItem direction="row" title="ID" value={data.key || "--"} />
        <DetailsGridItem
          direction="row"
          title="Owner"
          value={data.email || "--"}
        />
      </Grid>
      <Grid
        alignItems="center"
        columnGap={8}
        direction="row"
        sx={{ alignItems: "start", pb: 4 }}
        container
      >
        <DetailsGridItem
          direction="column"
          title={securityContextTitle}
          value={
            data.securityContext || (
              <NoSecurityContext hasDiscovery={Boolean(data.discovery)} />
            )
          }
        />
        <DetailsGridItem
          direction="column"
          title="Path"
          value={data.path || "--"}
        />
        <DetailsGridItem
          direction="column"
          title="Validated At"
          value={<HumanTimestamp timestamp={data.validated_at} />}
        />
        {data.provider === "Azure" ? (
          <DetailsGridItem direction="row" title="Security Context">
            <Box
              sx={{
                backgroundColor: COLORS.navy.L90,
                borderRadius: "2px",
                display: "flex",
                alignItems: "start",
                padding: 2,
                marginY: 1,
              }}
            >
              <WithCopyButton
                sx={{ alignItems: "start" }}
                value={formattedAzureSecurityContext}
              >
                <Pre>{formattedAzureSecurityContext}</Pre>
              </WithCopyButton>
            </Box>
          </DetailsGridItem>
        ) : null}
        {data.provider === "TencentCloud" ? (
          <DetailsGridItem direction="row" title="API Key">
            <Box
              sx={{
                backgroundColor: COLORS.navy.L90,
                borderRadius: "2px",
                display: "flex",
                alignItems: "start",
                padding: 2,
                marginY: 1,
              }}
            >
              <WithCopyButton
                sx={{ alignItems: "start" }}
                value={formattedTencentCloudSecurityContext}
              >
                <Pre>{formattedTencentCloudSecurityContext}</Pre>
              </WithCopyButton>
            </Box>
          </DetailsGridItem>
        ) : null}
      </Grid>

      <Grid alignItems="center" columnGap={8} container>
        <DetailsGridItem direction="column" title="Tags">
          {tagsLabel?.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              sx={{ mr: 1 }}
              variant="outlined"
            />
          ))}
        </DetailsGridItem>
      </Grid>
    </DetailsMetaData>
  );
}
