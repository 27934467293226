/**
 * @generated SignedSource<<6ae41d3e54b387dab6f7482911416097>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type PoliciesRootQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
  first?: number | null | undefined;
  order?: string | null | undefined;
  overviewFilterElement?: FilterElementInput | null | undefined;
};
export type PoliciesRootQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OverviewPage_query" | "PoliciesTable_policies">;
};
export type PoliciesRootQuery = {
  response: PoliciesRootQuery$data;
  variables: PoliciesRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterElement"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "overviewFilterElement"
},
v4 = [
  {
    "kind": "Variable",
    "name": "filterElement",
    "variableName": "overviewFilterElement"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "filterElement",
    "variableName": "filterElement"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PoliciesRootQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "OverviewPage_query"
      },
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "PoliciesTable_policies"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PoliciesRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "whoami",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UrlConfig",
            "kind": "LinkedField",
            "name": "UrlConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "DashboardConnection",
        "kind": "LinkedField",
        "name": "dashboards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DashboardEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dashboard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RepositoryConnection",
        "kind": "LinkedField",
        "name": "repositories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RepositoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PolicyConnection",
        "kind": "LinkedField",
        "name": "policies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FilterSchema",
            "kind": "LinkedField",
            "name": "filterSchema",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Filter",
                "kind": "LinkedField",
                "name": "filters",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "suggestions",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "problems",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Problems",
            "abstractKey": "__isProblems"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PolicyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Policy",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "resource",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "severity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "resourceMatchCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "version",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "filterElement",
          "order"
        ],
        "handle": "connection",
        "key": "Policies_policies",
        "kind": "LinkedHandle",
        "name": "policies"
      }
    ]
  },
  "params": {
    "cacheID": "7a205c72c94912a25fb13e0328954e8c",
    "id": null,
    "metadata": {},
    "name": "PoliciesRootQuery",
    "operationKind": "query",
    "text": "query PoliciesRootQuery(\n  $first: Int\n  $filterElement: FilterElementInput\n  $overviewFilterElement: FilterElementInput\n  $order: String\n) {\n  ...OverviewPage_query_P3LeK\n  ...PoliciesTable_policies_4gPC2N\n}\n\nfragment BreadCrumbsHeader_query on Query {\n  whoami {\n    email\n    displayName\n  }\n}\n\nfragment FilterBar_filters on FilterSchema {\n  filters {\n    __typename\n  }\n  ...FilterModal_filters\n}\n\nfragment FilterModal_filters on FilterSchema {\n  filters {\n    name\n    suggestions\n  }\n}\n\nfragment NoPolicies_repositories on RepositoryConnection {\n  edges {\n    __typename\n  }\n}\n\nfragment OverviewPage_query_P3LeK on Query {\n  ...PageWithBreadCrumbs_query\n  ...useEnabledDashboards_query_P3LeK\n}\n\nfragment PageWithBreadCrumbs_query on Query {\n  ...BreadCrumbsHeader_query\n}\n\nfragment PoliciesFilter_filterSchema on PolicyConnection {\n  filterSchema {\n    ...FilterBar_filters\n  }\n}\n\nfragment PoliciesTable_policies_4gPC2N on Query {\n  repositories {\n    ...NoPolicies_repositories\n  }\n  policies(first: $first, filterElement: $filterElement, order: $order) {\n    ...PoliciesFilter_filterSchema\n    ...useProblemsAlert_problems\n    edges {\n      __typename\n      node {\n        id\n        description\n        name\n        resource\n        mode\n        severity\n        tags {\n          key\n        }\n        resourceMatchCount\n        uuid\n        version\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n      total\n    }\n  }\n}\n\nfragment useEnabledDashboards_query_P3LeK on Query {\n  dashboards(filterElement: $overviewFilterElement) {\n    edges {\n      node {\n        key\n        title\n        id\n      }\n    }\n  }\n}\n\nfragment useProblemsAlert_problems on Problems {\n  __isProblems: __typename\n  problems {\n    __typename\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "5578ca37b51076be6b63c0f73eb13c85";

export default node;
