import { SideBar } from "@stacklet/ui";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { useLocation, useNavigate } from "react-router-dom";

import { ProviderSelector } from "app/components/ProviderSelector";
import { useConfig } from "app/contexts";
import { useEnabledDashboards } from "app/hooks/useEnabledDashboards/useEnabledDashboards";

import { NavBarUserMenu } from "../NavBarUserMenu";
import { useNavItems } from "./hooks";

import type { SideBarWrapper_query$key } from "./__generated__/SideBarWrapper_query.graphql";
import type { Provider } from "app/contexts/ProviderContext";

interface Props {
  onProviderChange: (provider: Provider) => void;
  query: SideBarWrapper_query$key;
}
export function SideBarWrapper({ onProviderChange, query }: Props) {
  const config = useConfig();
  const location = useLocation();
  const navigate = useNavigate();

  const data = useFragment(
    graphql`
      fragment SideBarWrapper_query on Query
      @argumentDefinitions(filterElement: { type: "FilterElementInput" }) {
        UrlConfig {
          jun0
          redash
          sinistral
        }
        ...useEnabledDashboards_query @arguments(filterElement: $filterElement)
      }
    `,
    query,
  );

  const appSelectorOptions = [
    ...(data.UrlConfig?.redash
      ? [
          {
            label: "AssetDB",
            href: data.UrlConfig?.redash,
            isBeta: false,
          },
        ]
      : []),
    {
      label: "Console",
      href: "",
      isBeta: false,
    },
    ...(config?.dev_flags?.includes("jun0") && data.UrlConfig?.jun0
      ? [
          {
            label: "Jun0",
            href: data.UrlConfig?.jun0,
            isBeta: true,
          },
        ]
      : []),

    ...(data.UrlConfig?.sinistral
      ? [
          {
            label: "IaC Governance",
            href: data.UrlConfig?.sinistral,
            isBeta: false,
          },
        ]
      : []),
  ];

  const enabledDashboards = useEnabledDashboards(data);
  const navItems = useNavItems(enabledDashboards);

  return (
    <SideBar
      ProviderSelector={<ProviderSelector onChange={onProviderChange} />}
      SignInMenu={<NavBarUserMenu />}
      appSelectorOptions={appSelectorOptions}
      navItems={navItems}
      navigate={navigate}
      pathname={location.pathname}
    />
  );
}
