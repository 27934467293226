import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { graphql, useFragment } from "react-relay";

import { Link } from "app/components";
import NoData from "app/components/empty-state/NoData";

import type { Remediations_recommendedActions$key } from "./__generated__/Remediations_recommendedActions.graphql";

interface RecommenedAction {
  remediation: Remediation[];
}

interface Remediation {
  name: string;
  type: string;
  content: string;
  description: string;
  saving_opportunity: number;
  performance_opportunity: number;
}

interface Props {
  dashboardSection: Remediations_recommendedActions$key;
}

export function Remediations({ dashboardSection }: Props) {
  const data = useFragment(
    graphql`
      fragment Remediations_recommendedActions on DashboardSection {
        recommendedActions
      }
    `,
    dashboardSection,
  );

  if (!data.recommendedActions) {
    return <NoData message="There are no recommended remediations currently" />;
  }

  const recommendedActions: RecommenedAction = JSON.parse(
    data.recommendedActions,
  );

  return (
    <List sx={{ p: 0 }}>
      {(recommendedActions?.remediation ?? []).map((remediation) => (
        <ListItem key={remediation.name} sx={{ pl: 0 }}>
          {remediation.type === "link" ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <Link href={remediation.content}>{remediation.description}</Link>
            </Typography>
          ) : (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {remediation.description}: {remediation.content}
            </Typography>
          )}
        </ListItem>
      ))}
    </List>
  );
}
