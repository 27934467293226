import { useMemo } from "react";

import { useCubeQuery } from "@cubejs-client/react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { graphql, useFragment } from "react-relay";

import NoData from "app/components/empty-state/NoData";
import { formatProvider } from "app/utils/formatProvider";

import RadialProgressChart from "../../charts/RadialProgressChart";
import { CardSkeleton } from "../CardSkeleton";

import type { PerSection_dashboard$key } from "./__generated__/PerSection_dashboard.graphql";
import type { Provider } from "app/contexts/ProviderContext";

interface Props {
  dashboard: PerSection_dashboard$key;
}

export function PerSection({ dashboard }: Props) {
  const data = useFragment(
    graphql`
      fragment PerSection_dashboard on Dashboard {
        key
        title
        providers
        flattenedSections {
          path
          title
        }
      }
    `,
    dashboard,
  );

  const provider = data?.providers?.length
    ? (data.providers[0].toLowerCase() as Provider)
    : "";

  const sectionTitles = useMemo(() => {
    const sectionHeadings = data.flattenedSections?.filter(
      (section) => section.path?.length === 1,
    );

    if (sectionHeadings) {
      return sectionHeadings.map((heading) => heading.title);
    }
    return [];
  }, [data.flattenedSections]);

  // We use "Yesterday" here b/c we might not have ControlSignal data for today yet
  const mostRecentDate = "Yesterday";
  const { resultSet, isLoading, error } = useCubeQuery(
    [
      {
        measures: ["ResourceCount.resourceCount"],
        timeDimensions: [
          {
            dimension: "ResourceCount.date",
            granularity: "day",
            dateRange: mostRecentDate,
          },
        ],
        dimensions: ["Dashboard.key", "DashboardSection.title"],
        filters: [
          {
            member: "Dashboard.key",
            operator: "equals",
            values: [data.key],
          },
          {
            member: "DashboardSection.title",
            operator: "equals",
            values: sectionTitles,
          },
          {
            member: "Account.provider",
            operator: "equals",
            values: [provider],
          },
        ],
        order: [["DashboardSection.title", "asc"]],
      },
      {
        measures: ["ControlSignal.distinctResources"],
        timeDimensions: [
          {
            dimension: "ControlSignal.date",
            granularity: "day",
            dateRange: mostRecentDate,
          },
        ],
        dimensions: ["Dashboard.key", "DashboardSection.title"],
        filters: [
          {
            member: "Dashboard.key",
            operator: "equals",
            values: [data.key],
          },
          {
            member: "DashboardSection.title",
            operator: "equals",
            values: sectionTitles,
          },
          {
            member: "Account.provider",
            operator: "equals",
            values: [provider],
          },
        ],
        order: [["DashboardSection.title", "asc"]],
      },
    ],
    { skip: !data },
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "220px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {[...Array(5)].map((_, idx) => (
          <CardSkeleton key={idx} />
        ))}
      </Box>
    );
  }

  if (error) {
    Sentry.captureException(error);
    console.error(error.toString());
  }

  if (!resultSet) {
    return null;
  }

  // create map of violations per section
  const [totals, violations] = resultSet.decompose().map((r) => r.rawData());
  const violationsMap = new Map<string, number>();
  for (const violation of violations) {
    const section = violation["DashboardSection.title"] as string;
    const count = Number(violation["ControlSignal.distinctResources"]);
    violationsMap.set(section, count);
  }

  // calculate compliance percentage for each section
  totals.forEach((item) => {
    const section = item["DashboardSection.title"] as string;
    const totalResources = Number(item["ResourceCount.resourceCount"]);
    const matchingResources = violationsMap.get(section) || 0;
    item["compliancePercentage"] =
      (100 * (totalResources - matchingResources)) / totalResources;
  });

  // sort by compliance percentage
  totals.sort((a, b) => {
    const aCompliance = a["compliancePercentage"] as number;
    const bCompliance = b["compliancePercentage"] as number;
    return aCompliance - bCompliance;
  });

  const rows = totals;

  if (!rows?.length) {
    return <NoData message="No metrics found." />;
  }

  return (
    <>
      <Box sx={{ width: "100vw" }}>
        <Typography variant="h2">{`Compliance by ${data.title} section`}</Typography>
      </Box>
      <Typography variant="body2">
        {`Shows the percentage of ${formatProvider(
          provider,
        )} cloud resources in compliance in each of the CIS Benchmark sections evaluated`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {rows.map((row) => {
          const id = row["DashboardSection.title"] as string;
          const compliance = row["compliancePercentage"] as number;

          return (
            <RadialProgressChart key={id} percentage={compliance} title={id} />
          );
        })}
      </Box>
    </>
  );
}
