import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";

type Action = "Remove" | "Delete";

interface Props {
  openDialog(arg: boolean): void;
  confirmDelete(): void;
  isDialogOpen: boolean;
  title: string;
  action?: Action;
  redirectPath?: string;
  deleteButtonTestId?: string;
}

export function DeleteConfirmationDialog({
  title,
  openDialog,
  confirmDelete,
  isDialogOpen,
  action = "Delete",
  redirectPath,
  deleteButtonTestId = "confirmation-dialog-delete-button",
}: Props) {
  const navigate = useNavigate();
  const routeChange = () => {
    if (redirectPath) {
      navigate(redirectPath);
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-create-group"
        maxWidth="sm"
        onClose={() => {
          openDialog(false);
        }}
        open={isDialogOpen}
        fullWidth
      >
        <DialogTitle
          aria-labelledby="form-dialog-title"
          className="dialogTitle"
          data-testid="confirm-delete-dialog-title"
          id="form-dialog-create-group"
        >
          {`${action} ${title}`}
        </DialogTitle>
        <DialogContent sx={{ py: 3 }}>
          {`Are you sure you want to ${action.toLowerCase()} the ${title.toLowerCase()}? This operation is not recoverable.`}
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ mt: 4, mx: 0, mb: 0 }}
          >
            <Button
              aria-label="cancel delete operation"
              color="primary"
              onClick={() => {
                openDialog(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              aria-label="confirm delete operation"
              color="primary"
              data-testid={deleteButtonTestId}
              onClick={() => {
                confirmDelete();
                if (redirectPath) {
                  routeChange();
                }
              }}
              type="submit"
              variant="contained"
            >
              {action}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
