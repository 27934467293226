import { useCallback, useMemo, useState, useTransition } from "react";

import { useCubeQuery } from "@cubejs-client/react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { Tooltip } from "@stacklet/ui";
import { graphql, useFragment, useQueryLoader } from "react-relay";
import { useDeepCompareMemo } from "use-deep-compare";

import { Currency } from "app/components/Currency";
import SeverityIcon from "app/components/icons/SeverityIcon";
import { TableSkeleton } from "app/components/skeletons";
import Table from "app/components/table/XGridTable";

import useControlRows from "../../hooks/useControlRows";
import { NoPolicyRecommendations } from "../NoPolicyRecommendations";
import { SectionModal, sectionModalQuery } from "../SectionModal/SectionModal";

import type {
  PolicyTable_dashboard$data,
  PolicyTable_dashboard$key,
} from "./__generated__/PolicyTable_dashboard.graphql";
import type { SectionModalQuery } from "../SectionModal/__generated__/SectionModalQuery.graphql";
import type { Query } from "@cubejs-client/core";
import type {
  GridComparatorFn,
  GridRenderCellParams,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid-pro";
import type { $TSFixMe } from "App";
import type { SeverityLevel } from "app/components/icons/SeverityIcon";
import type { Provider } from "app/contexts/ProviderContext";
import type { NoRefs } from "types/utils";

export type Section = NoRefs<
  NonNullable<PolicyTable_dashboard$data["flattenedSections"]>
>[0];

export function getParents(
  path: readonly string[],
  sections: Map<string, Section>,
) {
  return path.slice(0, -1).map((id) => sections.get(id));
}

interface Props {
  dashboard: PolicyTable_dashboard$key;
}

export function PolicyTable({ dashboard }: Props) {
  const [includeWithoutPolicies, setIncludeWithoutPolicies] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [sectionKey, setSectionKey] = useState<string | null>(null);
  const [sectionModalQueryRef, loadSectionModalQuery] =
    useQueryLoader<SectionModalQuery>(sectionModalQuery);
  const [, startTransition] = useTransition();

  const data = useFragment(
    graphql`
      fragment PolicyTable_dashboard on Dashboard {
        key
        title
        description
        documentation
        flattenedSections {
          id
          hasPolicies
          path
          key
          title
          description
          documentation
          recommendedActions
          resourceTypes {
            unqualifiedName
          }
          severity
          topSection {
            id
            title
          }
        }
        providers
      }
    `,
    dashboard,
  );

  const { flattenedSections } = data;

  // tree of sections by id
  const sectionTree: Record<string, GridRowId[]> = useMemo(() => {
    const nodes: Record<string, GridRowId[]> = {};
    flattenedSections?.forEach((row, index) => {
      const value: GridRowId[] = [];
      const id = row.id;
      for (let i = index; i < flattenedSections.length; i++) {
        if (flattenedSections[i].path?.includes(id)) {
          value.push(flattenedSections[i].id);
        } else {
          break;
        }
      }
      nodes[row.id] = value;
    });
    return nodes;
  }, [flattenedSections]);

  const isLeafNode = useCallback(
    (id: GridRowId) => sectionTree[id] && sectionTree[id].length === 1,
    [sectionTree],
  );

  const isCostDashboard = data.key.includes("cost");
  const isSoc2Dashboard = data.key.includes("soc2");

  // We use "Yesterday" here b/c we might not have ControlSignal data for today yet
  const mostRecentDate = "Yesterday";

  const provider = data?.providers?.length
    ? (data.providers[0].toLowerCase() as Provider)
    : "";

  const query = {
    measures: ["ResourceCount.resourceCount"],
    dimensions: ["DashboardSection.title"],
    timeDimensions: [
      {
        dimension: "ResourceCount.date",
        granularity: "day",
        dateRange: mostRecentDate,
      },
    ],
    filters: [
      {
        member: "Dashboard.key",
        operator: "equals",
        values: [data.key],
      },
      {
        member: "Account.provider",
        operator: "equals",
        values: [provider],
      },
    ],
    order: [["DashboardSection.title", "asc"]],
  } as Query;

  const violationsQuery = {
    measures: ["ControlSignal.distinctResources"],
    dimensions: ["DashboardSection.title"],
    timeDimensions: [
      {
        dimension: "ControlSignal.date",
        granularity: "day",
        dateRange: "Last 30 days",
      },
    ],
    filters: [
      {
        member: "Dashboard.key",
        operator: "equals",
        values: [data.key],
      },
      {
        member: "Account.provider",
        operator: "equals",
        values: [provider],
      },
    ],
    order: [["DashboardSection.title", "asc"]],
  } as Query;

  const costQuery = {
    measures: ["ResourceCostSummaries.dailyUnblendedCost"],
    dimensions: ["DashboardSection.key"],
    timeDimensions: [
      {
        dimension: "ControlSignal.date",
        granularity: "day",
        dateRange: mostRecentDate,
      },
    ],
    filters: [
      {
        member: "Dashboard.key",
        operator: "equals",
        values: [data.key],
      },
      {
        member: "Resources.provider",
        operator: "equals",
        values: [provider],
      },
    ],
    order: [["DashboardSection.key", "asc"]],
  } as Query;

  const { resultSet, isLoading: isLoadingCounts } = useCubeQuery(query);
  const { resultSet: violationsResultSet, isLoading: isLoadingViolations } =
    useCubeQuery(violationsQuery);
  const { resultSet: costResultSet, isLoading: isLoadingCost } =
    useCubeQuery(costQuery);

  const isLoading = isLoadingCounts || isLoadingViolations || isLoadingCost;

  const results = useMemo(() => {
    if (resultSet?.rawData) {
      return resultSet.rawData();
    }
  }, [resultSet]);

  const violationsResults = useMemo(() => {
    if (violationsResultSet) {
      return violationsResultSet.chartPivot();
    }
  }, [violationsResultSet]);

  const costResults = useMemo(() => {
    if (costResultSet) {
      return costResultSet?.chartPivot();
    }
  }, [costResultSet]);

  const sectionsMap = useDeepCompareMemo(
    () =>
      new Map<string, Section>(
        flattenedSections?.map((section) => [section.id, section]),
      ),
    [flattenedSections],
  );

  const sections = useDeepCompareMemo(
    () =>
      (flattenedSections ?? [])
        .filter((section) => isLeafNode(section.id)) // remove headings
        .filter((section) =>
          !includeWithoutPolicies ? section.recommendedActions : true,
        ) // only include rows with recommendations
        .filter((section) =>
          !includeWithoutPolicies ? section.hasPolicies : true,
        )
        .map((section) => ({
          id: section.id,
          hasPolicies: section.hasPolicies,
          path: section.path,
          key: section.key,
          title: section.title,
          description: section.description,
          documentation: section.documentation,
          resourceTypes: section.resourceTypes,
          severity: section.severity,
          topSection: section.topSection,
          parents: getParents(section.path, sectionsMap),
          recommendedActions: section.recommendedActions
            ? (JSON.parse(section.recommendedActions) as string)
            : null,
        })),
    [flattenedSections, includeWithoutPolicies],
  );

  const rows =
    useControlRows(sections, results, violationsResults, costResults) || [];

  const LevelComparator: GridComparatorFn = (a, b) => {
    const orders: Record<string, number> = {
      low: 0,
      medium: 1,
      high: 2,
      critical: 3,
    };
    return orders[a] - orders[b];
  };

  function handleModalOpen(sectionKey: string) {
    if (!modalOpen) {
      setModalOpen(true);
    }
    setSectionKey(sectionKey);
    startTransition(() => loadSectionModalQuery({ key: data.key, sectionKey }));
  }

  function handleModalClose() {
    setModalOpen(false);
  }

  type SectionRow = (typeof rows)[0] & { parents: Section[] };

  const columns = useDeepCompareMemo<GridColDef<SectionRow>[]>(
    () => [
      {
        headerName: "Title",
        field: "title",
        minWidth: 500,
        flex: 1,
        renderCell: (params) => {
          const { hasPolicies, key, title } = params.row;

          if (hasPolicies) {
            return (
              <Link
                component={Button}
                onClick={() => handleModalOpen(key)}
                sx={{
                  "& .MuiButton-label": {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    display: "inline-block",
                  },
                }}
              >
                <Typography noWrap>{title}</Typography>
              </Link>
            );
          }
          return (
            <Typography sx={{ ml: 1 }} noWrap>
              {title}
            </Typography>
          );
        },
      },
      {
        headerName: "Section",
        field: "parents",
        flex: 1,
        minWidth: 200,
        valueGetter: (params) => {
          const { parents } = params.row;
          const sectionTitles = parents.map((parent) => parent.title);
          if (isSoc2Dashboard) {
            return sectionTitles[0];
          }
          return sectionTitles.join(" / ");
        },
      },
      {
        headerName: "Estimated Monthly Cost",
        headerAlign: "right",
        align: "right",
        field: "monthlyCost",
        minWidth: 220,
        flex: 1,
        renderHeaderFilter: () => null,
        renderCell: (params: GridRenderCellParams) => (
          <Currency amount={params.row.dailyUnblendedCost * 30} />
        ),
        renderHeader: () => {
          return (
            <Tooltip
              title="Approximate 30-day cost of resources in the category.
                   Because a single resource can appear in multiple categories, the cost of
                   a category may not equal the sum of its parts."
            >
              <span>
                <InfoOutlinedIcon
                  sx={{
                    fontSize: "1rem",
                    verticalAlign: "text-bottom",
                    mr: "5px",
                  }}
                />
                Estimated Monthly Cost
              </span>
            </Tooltip>
          );
        },
        valueGetter: (params) => params.row.dailyUnblendedCost * 30,
      },
      {
        headerName: "Daily Cost",
        headerAlign: "right",
        align: "right",
        field: "dailyUnblendedCost",
        width: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Currency amount={params.row.dailyUnblendedCost} />
        ),
        renderHeader: () => {
          return (
            <Tooltip
              title="Average daily cost of resources in the category.
                   Because a single resource can appear in multiple categories, the cost of
                   a category may not equal the sum of its parts."
            >
              <span>
                <InfoOutlinedIcon
                  sx={{
                    fontSize: "1rem",
                    verticalAlign: "middle",
                    mr: "5px",
                  }}
                />
                Daily Cost
              </span>
            </Tooltip>
          );
        },
      },
      {
        headerName: "Severity",
        align: "center",
        field: "severity",
        width: 100,
        flex: 1,
        renderCell: (params) => {
          const { severity } = params.row;
          if (severity) {
            return <SeverityIcon severityLevel={severity as SeverityLevel} />;
          }
        },
        sortComparator: LevelComparator,
        type: "singleSelect",
        valueOptions: ["low", "medium", "high", "critical"],
      },
      {
        headerName: "Type",
        field: "resourceTypes",
        flex: 1,
        valueGetter: (params: GridRenderCellParams) => {
          const { resourceTypes } = params.row;
          return resourceTypes
            .map((resourceType: $TSFixMe) => resourceType.unqualifiedName)
            .join(", ");
        },
      },
      {
        headerName: "Score",
        field: "passing",
        width: 80,
        flex: 1,
        headerAlign: "right",
        align: "right",
        renderCell: (params) => {
          const { hasPolicies, passing, resources } = params.row;

          if (!hasPolicies) {
            return "n/a";
          }

          if (!resources || resources === 0) {
            return null;
          }

          return `${passing}%`;
        },
      },
      {
        headerName: isCostDashboard ? "Resources" : "Findings",
        headerAlign: "right",
        align: "right",
        field: "violations",
        width: 80,
        flex: 1,
        renderCell: (params) => {
          const { hasPolicies, violations } = params.row;
          if (!hasPolicies) {
            return "n/a";
          }

          return <span>{violations || 0}</span>;
        },
      },
      {
        headerName: "Resources",
        headerAlign: "right",
        align: "right",
        field: "resources",
        width: 120,
        flex: 1,
        renderCell: (params) => {
          const { hasPolicies, resources } = params.row;
          if (!hasPolicies) {
            return "n/a";
          }
          return <span>{resources || 0}</span>;
        },
      },
    ],
    [isCostDashboard, results, violationsResults, costResults],
  );

  if (!flattenedSections) {
    return null;
  }

  const columnVisibilityModel = {
    monthlyCost: isCostDashboard,
    dailyUnblendedCost: false,
    passing: !isCostDashboard,
    resources: !isCostDashboard,
    severity: !isCostDashboard,
  };

  return (
    <>
      {sectionKey && sectionModalQueryRef ? (
        <SectionModal
          onClose={handleModalClose}
          open={modalOpen}
          queryRef={sectionModalQueryRef}
        />
      ) : null}

      <Box sx={{ mb: 2 }}>
        <Typography variant="h2">Recommendations</Typography>
        <Typography variant="body2">
          {`The list of recommended actions from the ${data.title} benchmark is below. Many of these recommendations are evaluated by Stacklet, while others must be assessed manually. We have included the complete list below for reference. However, only recommendations evaluated by Stacklet have resource findings and are included in compliance percentages.`}
        </Typography>
      </Box>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          customInline={
            <NoPolicyRecommendations onChange={setIncludeWithoutPolicies} />
          }
          getRowId={(row) => row.key}
          hasToolbar={true}
          initialState={{
            columns: {
              columnVisibilityModel,
            },
          }}
          loading={!rows?.length || isLoading}
          rows={rows}
          tableId={`dashboards-policy-table-${data.key}`}
          disableVirtualization
          enableFilterButton
        />
      )}
    </>
  );
}
