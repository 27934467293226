import { useState, useMemo } from "react";

import Box from "@mui/material/Box";
import { Tooltip } from "@stacklet/ui";
import { nanoid } from "nanoid";
import { graphql } from "react-relay";
import { usePaginationFragment } from "react-relay/hooks";

import { ProviderIcon } from "app/components/icons/ProviderIcon";
import { TableLink } from "app/components/links";
import Table from "app/components/table/XGridTable";
import ToolbarHeader from "app/components/ToolbarHeader";
import { useLoadNext } from "app/hooks";
import CheckMark from "assets/images/green_check_mark.svg?react";

import { RemoveAccountFromGroups } from "./actions/RemoveAccountFromGroups";

import type {
  AccountGroupsTableDetailFragment$key,
  CloudProvider,
} from "./__generated__/AccountGroupsTableDetailFragment.graphql";
import type { AccountGroupsTablePaginationQuery } from "./__generated__/AccountGroupsTablePaginationQuery.graphql";
import type { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";

interface Props {
  account: AccountGroupsTableDetailFragment$key;
  showActions?: boolean;
}

interface AccountGroup {
  nodeId: string;
  uuid: string;
  name: string;
  provider: CloudProvider;
  itemCount: number | null;
  description?: string | null;
  regions?: readonly string[] | null;
}

export function AccountGroupsTableDetail({
  account,
  showActions = false,
}: Props) {
  const [checkedAccounts, setCheckedAccounts] = useState<AccountGroup[]>([]);

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    AccountGroupsTablePaginationQuery,
    AccountGroupsTableDetailFragment$key
  >(
    graphql`
      fragment AccountGroupsTableDetailFragment on Account
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
        filterElement: { type: "FilterElementInput" }
      )
      @refetchable(queryName: "AccountGroupsTablePaginationQuery") {
        groupMappings(
          first: $first
          last: $last
          after: $after
          before: $before
          filterElement: $filterElement
        ) @connection(key: "AccountGroupsTable_groupMappings") {
          edges {
            node {
              id
              group {
                dynamicFilter
                uuid
                name
                description
                regions
                provider
                accountMappings {
                  pageInfo {
                    total
                  }
                }
              }
            }
          }
          pageInfo {
            total
          }
        }
      }
    `,
    account,
  );

  const loadMore = useLoadNext(hasNext, loadNext);

  const rows = (data.groupMappings.edges ?? [])
    ?.filter((edge) => edge.node?.group)
    .map((edge, index) => {
      const { id, group } = edge.node;
      return {
        nodeId: id,
        dynamicFilter: group.dynamicFilter,
        uuid: group.uuid,
        name: group.name,
        regions: group.regions,
        description: group.description,
        provider: group.provider,
        itemCount:
          (data.groupMappings.edges ?? []).map(
            (edge) => edge.node.group.accountMappings.pageInfo.total,
          )[index] || 0,
      };
    });

  type GroupMappingRow = (typeof rows)[0];

  const columns: GridColDef<GroupMappingRow>[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        renderCell: (params) => {
          const { name, description, uuid } = params.row;

          const url = showActions
            ? `/admin/account-groups/${uuid}`
            : `/account-groups/${uuid}`;

          return <TableLink description={description} name={name} to={url} />;
        },
      },
      {
        headerName: "Dynamic",
        field: "dynamicFilter",
        renderCell: (params) => {
          const { dynamicFilter } = params.row;
          // dynamicFilter can be ""
          if (dynamicFilter !== null && dynamicFilter !== undefined) {
            const id = nanoid();
            return (
              <Tooltip
                id={id}
                title="Dynamic account groups dynamically add and remove accounts based on a filter expression"
              >
                <span>
                  <CheckMark aria-labelledby={id} />
                </span>
              </Tooltip>
            );
          }
          return "";
        },
      },
      {
        headerName: "Provider",
        field: "provider",
        renderCell: (params) => <ProviderIcon provider={params.row.provider} />,
      },
      {
        headerName: "Regions",
        field: "regions",
        renderCell: (params) => {
          const { regions } = params.row;

          if (Array.isArray(regions) && regions.length) {
            return (
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {regions.join(", ")}
              </Box>
            );
          }
          return "--";
        },
      },
      {
        headerName: "# of Accounts",
        field: "itemCount",
        type: "number",
      },
    ],
    [showActions],
  );

  const updateSelected = (selectionModel: GridRowSelectionModel) => {
    const selectedAccountGroups = rows.filter((row) =>
      selectionModel.includes(row.nodeId),
    );
    setCheckedAccounts(selectedAccountGroups || []);
  };

  const checkedAccountGroupMappingId = checkedAccounts.map((ag) => ag.nodeId);

  const rowCount = data.groupMappings.pageInfo.total || rows.length;

  return (
    <Box
      data-testid="accountgroup-table"
      height="80%"
      p={3}
      sx={{ backgroundColor: (theme) => theme.palette.common.white }}
    >
      <ToolbarHeader title="Search Account Group">
        {showActions ? (
          <RemoveAccountFromGroups mappingIds={checkedAccountGroupMappingId} />
        ) : null}
      </ToolbarHeader>
      <Table
        columns={columns}
        getRowId={(row) => row.nodeId}
        initialState={{
          sorting: {
            sortModel: [{ field: "dynamicFilter", sort: "asc" }],
          },
        }}
        isRowSelectable={(params) =>
          params.row.dynamicFilter == null &&
          params.row.dynamicFilter == undefined
        }
        loading={isLoadingNext}
        onRowSelectionModelChange={updateSelected}
        onRowsScrollEnd={loadMore}
        rowCount={rowCount}
        rowSelectionModel={checkedAccounts.map((checked) => checked.nodeId)}
        rows={rows}
        tableId="admin-clouds-account-details"
        checkboxSelection
      />
    </Box>
  );
}
