import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Card, COLORS } from "@stacklet/ui";
import { formatDistance } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { graphql, useFragment } from "react-relay/hooks";

import { Title } from "app/components/grid/DetailGridValue";
import { DATETIME_FORMAT_STR } from "app/utils/consts";

import { BindingStatusBar } from "./BindingStatusBar";
import { BindingStatusEnum } from "./types";

import type { BindingStatusCard_runs$key } from "./__generated__/BindingStatusCard_runs.graphql";
import type { BindingProgress } from "./types";

const Stat = styled("span")(({ theme }) => ({
  padding: theme?.spacing(0, 0.5, 0, 0),
  opacity: 1.0,
  fontWeight: 500,
}));

const Label = styled("span")(() => ({
  opacity: 0.8,
}));

function friendlyTime(time: string) {
  if (!time) {
    return "0s";
  }
  const [h, m, s] = time.split(":");
  function labeledOrEmpty(n: string, label: string) {
    return n === "00" ? "" : `${parseInt(n)}${label}`;
  }
  return `${labeledOrEmpty(h, "h ")}${labeledOrEmpty(m, "m ")}${labeledOrEmpty(
    s,
    "s",
  )}`;
}

interface Props {
  progress: BindingProgress | null;
  binding: BindingStatusCard_runs$key;
}

export function BindingStatusCard({ progress, binding }: Props) {
  const data = useFragment(
    graphql`
      fragment BindingStatusCard_runs on Binding {
        runs {
          edges {
            node {
              startTime
              lastUpdateTime
              observedExecutionCount
              expectedExecutionCount
              executionErrorCount
            }
          }
        }
      }
    `,
    binding,
  );

  const lastRun = data?.runs?.edges?.[0]?.node || null;
  let title,
    elapsedTime,
    savedExecutions,
    expectedExecutions,
    errors,
    startTime,
    timeLabel;

  if (!progress) {
    title = "Loading\u2026";
  } else if (progress.status === BindingStatusEnum.RUNNING) {
    title = "Binding is running";
    elapsedTime = friendlyTime(progress.elapsedTime);
    savedExecutions = progress.savedExecutions;
    expectedExecutions = progress.expectedExecutions;
    errors = progress.errorCount;
    startTime = progress.startTime;
    timeLabel = "elapsed";
  } else if (progress.status === BindingStatusEnum.NOT_RUNNING) {
    title = lastRun ? "Last binding run" : "Binding is not running";
    if (lastRun) {
      savedExecutions = lastRun.observedExecutionCount;
      expectedExecutions = lastRun.expectedExecutionCount;
      errors = lastRun.executionErrorCount;
      elapsedTime =
        lastRun.startTime && lastRun.lastUpdateTime
          ? formatDistance(
              new Date(lastRun.lastUpdateTime),
              new Date(lastRun.startTime),
            )
          : lastRun.startTime && expectedExecutions > 0
            ? "unknown"
            : "";
      startTime = lastRun.startTime;
      timeLabel = "total run time";
    }
  } else if (progress.status === BindingStatusEnum.STARTING) {
    title = "Binding is starting\u2026";
  }

  const showDetails =
    progress?.status === BindingStatusEnum.RUNNING ||
    (progress?.status === BindingStatusEnum.NOT_RUNNING && lastRun);

  const hasExecutions = expectedExecutions && expectedExecutions > 0;

  return (
    <Card
      sx={{
        background: COLORS.navy.L95,
        minHeight: "116px",
        p: 2,
      }}
    >
      <Title>{title}</Title>
      {showDetails ? (
        <Stack spacing={1.25}>
          <Box>
            {progress?.status === BindingStatusEnum.RUNNING ? (
              <BindingStatusBar progress={progress} />
            ) : null}
          </Box>
          {hasExecutions ? (
            <Box>
              <Stat>
                {savedExecutions} / {expectedExecutions}
              </Stat>{" "}
              <Label>executions</Label>
            </Box>
          ) : null}
          <Box>
            <Stat>{errors} errors</Stat>
          </Box>
          {expectedExecutions && expectedExecutions > 0 ? (
            <Box>
              <Stat>{elapsedTime}</Stat> <Label>{timeLabel}</Label>
            </Box>
          ) : (
            ""
          )}
          {startTime ? (
            <Box>
              <Typography sx={{ opacity: 0.8 }} variant="xsmall">
                {`Started ${formatInTimeZone(
                  startTime,
                  "UTC",
                  DATETIME_FORMAT_STR,
                )} UTC`}
              </Typography>
            </Box>
          ) : null}
        </Stack>
      ) : null}
    </Card>
  );
}
