import { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Button, COLORS } from "@stacklet/ui";

import type { BackdropProps } from "@mui/material/Backdrop";
import type { SvgIconProps } from "@mui/material/SvgIcon";

interface Props {
  actionLabel: string;
  children: React.ReactNode;
  ActionIcon?: (props: SvgIconProps) => JSX.Element;
  onApply?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
  disableOnEnter?: boolean;
  disabled?: boolean;
}

const StyledBackdropComponent = styled(Backdrop, {
  name: "MuiModal",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
  slot: "Backdrop",
})({ zIndex: -1 });

export default function ModalWithButton({
  children,
  ActionIcon,
  actionLabel,
  onApply,
  onCancel,
  onClose,
  onOpen,
  disableOnEnter,
  disabled = false,
}: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    handleClose();
  };

  const handleApply = () => {
    if (onApply) {
      onApply();
    }
    handleClose();
  };

  return (
    <Box sx={{ position: "relative" }}>
      <MuiButton onClick={handleOpen}>
        <Stack direction="row" sx={{ color: COLORS.navy.L20 }}>
          <AddIcon
            sx={{
              p: "6px",
              pr: 0,
            }}
          />
          <Box
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(14),
              fontWeight: 600,
              px: "3px",
              py: "5px",
              lineHeight: "18px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            {actionLabel}
          </Box>
        </Stack>
      </MuiButton>
      <Dialog
        BackdropComponent={(props: BackdropProps) => (
          <StyledBackdropComponent data-testid="dialog-backdrop" {...props} />
        )}
        aria-labelledby="modal-modal-title"
        data-testid="modal-with-button-dialog"
        maxWidth="md"
        onClose={handleClose}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (disableOnEnter) {
              e.preventDefault();
              return;
            }
            handleApply();
          }
        }}
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: `0px 12px 56px rgba(33, 43, 54, 0.1)`,
            margin: 0,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
        fullWidth
      >
        <DialogTitle
          sx={{
            fontSize: (theme) => theme.typography.pxToRem(18),
            lineHeight: "21px",
            textTransform: "capitalize",
            display: "flex",
          }}
        >
          <Stack alignItems="center" direction="row" gap={1}>
            {ActionIcon ? <ActionIcon /> : null}
            {actionLabel}
          </Stack>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions
          sx={{
            py: 1,
            px: 2,
            justifyContent: "space-between",
            borderTop: `1px solid ${COLORS.navy.L90}`,
          }}
        >
          <Button buttonType="tertiary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            buttonType="main"
            disabled={disabled}
            onClick={handleApply}
            type="submit"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
