import { Tooltip, COLORS } from "@stacklet/ui";

import { Link } from "./Link";

interface Props {
  to: string;
  children: React.ReactNode;
  description?: string;
}

export default function TooltipLink({
  to,
  children,
  description,
}: Props): JSX.Element {
  if (description) {
    return (
      <Link textColor={COLORS.cobalt.D30} to={to}>
        <Tooltip data-testid={`tooltip-${to}`} title={description}>
          <span>{children}</span>
        </Tooltip>
      </Link>
    );
  }

  return <Link to={to}>{children}</Link>;
}
