import { Suspense, useMemo, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import * as Sentry from "@sentry/browser";
import { graphql, useMutation, usePaginationFragment } from "react-relay";

import { TableLink } from "app/components";
import { DeleteConfirmationDialog } from "app/components/dialogs";
import NoData from "app/components/empty-state/NoData";
import { RectangleSkeleton } from "app/components/skeletons";
import Table from "app/components/table/XGridTable";
import TableActionMenu from "app/components/TableActionMenu";
import { useAlertContext } from "app/contexts";
import { useLoadNext } from "app/hooks";
import { RemovePolicyCollectionMappingsMutation } from "app/mutations";

import type { PolicyCollectionPolicies_policyMappings$key } from "./__generated__/PolicyCollectionPolicies_policyMappings.graphql";
import type { PolicyCollectionPoliciesRefetchQuery } from "./__generated__/PolicyCollectionPoliciesRefetchQuery.graphql";
import type { GridColDef } from "@mui/x-data-grid-pro";
import type {
  RemovePolicyCollectionMappingsMutation$data,
  RemovePolicyCollectionMappingsMutation as RemovePolicyCollectionMappingsMutationType,
} from "app/mutations/__generated__/RemovePolicyCollectionMappingsMutation.graphql";
import type { RecordSourceSelectorProxy } from "relay-runtime";

interface Props {
  policyCollection: PolicyCollectionPolicies_policyMappings$key;
  showActions?: boolean;
  pageInfoId?: string;
}

export function PolicyCollectionPolicies({
  policyCollection,
  pageInfoId,
  showActions = false,
}: Props) {
  const [isDeleteConfirmDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState<boolean>(false);
  const [commit, isMutationInFlight] =
    useMutation<RemovePolicyCollectionMappingsMutationType>(
      RemovePolicyCollectionMappingsMutation,
    );

  const { alertDispatch } = useAlertContext();
  const [nodeToBeRemoved, setNodeToBeRemoved] = useState<string>();

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    PolicyCollectionPoliciesRefetchQuery,
    PolicyCollectionPolicies_policyMappings$key
  >(
    graphql`
      fragment PolicyCollectionPolicies_policyMappings on PolicyCollection
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" })
      @refetchable(queryName: "PolicyCollectionPoliciesRefetchQuery") {
        policyMappings(first: $first, after: $after)
          @connection(key: "PolicyCollectionPolicies_policyMappings") {
          edges {
            node {
              id
              policy {
                uuid
                name
                version
              }
            }
          }
        }
      }
    `,
    policyCollection,
  );

  const loadMore = useLoadNext(hasNext, loadNext);

  const deletePolicyFromCollection = () => {
    setNodeToBeRemoved(undefined);
    setIsConfirmDeleteDialogOpen(false);

    function updatePageInfo(
      id: string,
      store: RecordSourceSelectorProxy<RemovePolicyCollectionMappingsMutation$data>,
    ) {
      const record = store.get(id);
      const total = record?.getValue("total") as number;
      if (record && total) {
        record.setValue(total - 1, "total");
      }
    }

    if (!nodeToBeRemoved) {
      return;
    }

    commit({
      variables: {
        input: {
          ids: [nodeToBeRemoved],
        },
      },
      onCompleted: (response, errors) => {
        if (errors) {
          alertDispatch({
            message: `error occurred when removing policy from collection: ${errors[0].message}`,
            severity: "error",
          });
        } else {
          alertDispatch({
            message: "successfully removed policy from collection",
            severity: "success",
          });
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
      optimisticUpdater: (store) => {
        if (pageInfoId) {
          updatePageInfo(pageInfoId, store);
        }
        store.delete(nodeToBeRemoved);
      },
      updater: (store) => {
        if (pageInfoId) {
          updatePageInfo(pageInfoId, store);
        }
      },
    });
  };

  function handleRemovePolicy(nodeId: string) {
    setIsConfirmDeleteDialogOpen(true);
    setNodeToBeRemoved(nodeId);
  }

  const openDialog = (value: boolean) => {
    setIsConfirmDeleteDialogOpen(value);
  };

  const confirmDelete = () => {
    deletePolicyFromCollection();
  };

  const columnVisibilityModel = {
    menuActions: showActions,
  };

  const rows = (data.policyMappings.edges ?? [])
    .filter((edge) => edge.node)
    .map((edge) => {
      const { policy, id } = edge.node;
      return {
        name: policy.name,
        nodeId: id,
        uuid: policy.uuid,
        version: policy.version,
      };
    });

  type PolicyRow = (typeof rows)[0];

  const columns: GridColDef<PolicyRow>[] = useMemo(
    () => [
      {
        headerName: "Policy Name",
        field: "name",
        renderCell: (params) => {
          const { name, version, uuid } = params.row;
          return (
            <TableLink
              name={name}
              to={`/policies/${uuid}/version/${version}`}
            />
          );
        },
      },
      {
        headerName: "Version",
        field: "version",
      },
      {
        headerName: "Actions",
        field: "menuActions",
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
          const { nodeId } = params.row;
          return (
            <TableActionMenu>
              <MenuItem
                aria-label="remove policy from collection"
                disabled={isMutationInFlight}
                onClick={() => handleRemovePolicy(nodeId)}
              >
                remove
              </MenuItem>
            </TableActionMenu>
          );
        },
      },
    ],
    [isMutationInFlight],
  );

  return (
    <Suspense fallback={<RectangleSkeleton height="800px" />}>
      {rows.length ? (
        <>
          <Table
            columnVisibilityModel={columnVisibilityModel}
            columns={columns}
            getRowId={(row) => row.uuid}
            hasToolbar={false}
            initialState={{
              columns: {
                columnVisibilityModel,
              },
            }}
            loading={isLoadingNext}
            onRowsScrollEnd={loadMore}
            rows={rows}
            tableId="admin-controls-policy-collections-details"
          />
          <DeleteConfirmationDialog
            action={"Remove"}
            confirmDelete={confirmDelete}
            isDialogOpen={isDeleteConfirmDialogOpen}
            openDialog={openDialog}
            title="policy from collection"
          />
        </>
      ) : (
        <NoData message="There are no policies in this collection." />
      )}
    </Suspense>
  );
}
