import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import { StatCard } from "@stacklet/ui";

import { EOM_DATE_STR } from "app/utils/date";
import { percentChanged } from "app/utils/math";

import { FindingsMenu } from "./FindingsMenu";
import { PercentChangeMessage } from "../../PercentChange";

import type { Query } from "@cubejs-client/core";
import type { $TSFixMe } from "App";

function buildQueries(
  dashboardKey: string,
  provider: string | null,
  dateRange?: string | [string, string],
  sectionKey?: string,
): Query[] {
  const filters = [
    {
      member: "Dashboard.key",
      operator: "equals",
      values: [dashboardKey],
    },
    ...(sectionKey
      ? [
          {
            member: "DashboardSection.key",
            operator: "equals",
            values: [sectionKey],
          },
        ]
      : []),
    ...(provider
      ? [
          {
            member: "Account.provider",
            operator: "equals",
            values: [provider],
          },
        ]
      : ([] as $TSFixMe)),
  ];

  return [
    {
      measures: ["ControlSignal.distinctResources"],
      timeDimensions: [
        {
          dimension: "ControlSignal.date",
          granularity: "day",
          dateRange,
        },
      ],
      filters,
    },
    {
      measures: ["ResourceCount.resourceCount"],
      filters,
    },
  ];
}

interface Props {
  dashboardKey: string;
  title: string;
  description?: string;
  enableMenu?: boolean;
  sectionKey?: string;
  provider?: string | null;
  showTotal?: boolean;
}

export function Findings({
  dashboardKey,
  sectionKey,
  description,
  enableMenu = false,
  provider = null,
  showTotal = false,
  title,
}: Props) {
  // We use "Yesterday" here b/c we might not have ControlSignal data for today yet
  const queryCurr = useCubeQuery(
    buildQueries(dashboardKey, provider, "Yesterday", sectionKey)[0],
  );

  const queryPrev = useCubeQuery(
    buildQueries(
      dashboardKey,
      provider,
      [EOM_DATE_STR, EOM_DATE_STR],
      sectionKey,
    )[0],
  );

  const queryCount = useCubeQuery(
    buildQueries(dashboardKey, provider, "Yesterday")[1],
  );

  const isLoading =
    queryCurr.isLoading || queryPrev.isLoading || queryCount.isLoading;

  const countCurr = parseInt(
    queryCurr.resultSet?.tablePivot()[0][
      "ControlSignal.distinctResources"
    ] as string,
    10,
  );

  const countPrev = parseInt(
    queryPrev.resultSet?.tablePivot()[0][
      "ControlSignal.distinctResources"
    ] as string,
    10,
  );

  const total = parseInt(
    queryCount.resultSet?.rawData()[0]["ResourceCount.resourceCount"],
    10,
  );

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        height="100px"
        role="progressbar"
        variant="rectangular"
      />
    );
  }

  const percent = percentChanged(countCurr, countPrev);
  const count = `${countCurr ? countCurr.toLocaleString() : 0}${
    showTotal && total ? ` out of ${total.toLocaleString()}` : ""
  }`;

  return (
    <StatCard
      description={description}
      menu={
        enableMenu ? (
          <FindingsMenu
            count={countCurr}
            dashboardKey={dashboardKey}
            sectionKey={sectionKey}
          />
        ) : null
      }
      message={<PercentChangeMessage percent={percent} />}
      title={title}
    >
      {count}
    </StatCard>
  );
}
