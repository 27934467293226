import awsResourceColumnData from "./awsResourceColumns.json";
import azureResourceColumnData from "./azureResourceColumns.json";
import gcpResourceColumnData from "./gcpResourceColumns.json";
import tencentcloudResourceColumnData from "./tencentcloudResourceColumns.json";

import type { GridColDef } from "@mui/x-data-grid-pro";

interface resourceColumns {
  columns: GridColDef[];
}

type LooseObjectResources = Record<string, resourceColumns>;

export const resourceColumnMapping: LooseObjectResources = {
  ...awsResourceColumnData,
  ...azureResourceColumnData,
  ...gcpResourceColumnData,
  ...tencentcloudResourceColumnData,
};
