/**
 * @generated SignedSource<<a86ca3185a94087eae3c9159f2f26ef8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type ComplianceDashboardQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
  key: string;
};
export type ComplianceDashboardQuery$data = {
  readonly dashboard: {
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"DashboardDescription_dashboard" | "OverallAnnualCost_dashboard" | "OverallCompliance_dashboard" | "OverallFindings_dashboard" | "PerSection_dashboard" | "PolicyTable_dashboard" | "Timeline_dashboard">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewPage_query">;
};
export type ComplianceDashboardQuery = {
  response: ComplianceDashboardQuery$data;
  variables: ComplianceDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterElement"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterElement",
    "variableName": "filterElement"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "documentation",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ComplianceDashboardQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "OverviewPage_query"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Dashboard",
        "kind": "LinkedField",
        "name": "dashboard",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OverallAnnualCost_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DashboardDescription_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OverallFindings_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OverallCompliance_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PolicyTable_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Timeline_dashboard"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PerSection_dashboard"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ComplianceDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "whoami",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UrlConfig",
            "kind": "LinkedField",
            "name": "UrlConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DashboardConnection",
        "kind": "LinkedField",
        "name": "dashboards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DashboardEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dashboard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Dashboard",
        "kind": "LinkedField",
        "name": "dashboard",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "providers",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metric",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DashboardSection",
            "kind": "LinkedField",
            "name": "flattenedSections",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPolicies",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
              },
              (v4/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recommendedActions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceType",
                "kind": "LinkedField",
                "name": "resourceTypes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unqualifiedName",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "severity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DashboardSection",
                "kind": "LinkedField",
                "name": "topSection",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e3839bea23c51f95919b47f961b0204",
    "id": null,
    "metadata": {},
    "name": "ComplianceDashboardQuery",
    "operationKind": "query",
    "text": "query ComplianceDashboardQuery(\n  $filterElement: FilterElementInput\n  $key: String!\n) {\n  ...OverviewPage_query_2g1q2o\n  dashboard(key: $key) {\n    title\n    ...OverallAnnualCost_dashboard\n    ...DashboardDescription_dashboard\n    ...OverallFindings_dashboard\n    ...OverallCompliance_dashboard\n    ...PolicyTable_dashboard\n    ...Timeline_dashboard\n    ...PerSection_dashboard\n    id\n  }\n}\n\nfragment BreadCrumbsHeader_query on Query {\n  whoami {\n    email\n    displayName\n  }\n}\n\nfragment DashboardDescription_dashboard on Dashboard {\n  description\n}\n\nfragment OverallAnnualCost_dashboard on Dashboard {\n  __typename\n  key\n  providers\n}\n\nfragment OverallCompliance_dashboard on Dashboard {\n  key\n  metric\n  providers\n  title\n}\n\nfragment OverallFindings_dashboard on Dashboard {\n  key\n  providers\n  title\n}\n\nfragment OverviewPage_query_2g1q2o on Query {\n  ...PageWithBreadCrumbs_query\n  ...useEnabledDashboards_query_2g1q2o\n}\n\nfragment PageWithBreadCrumbs_query on Query {\n  ...BreadCrumbsHeader_query\n}\n\nfragment PerSection_dashboard on Dashboard {\n  key\n  title\n  providers\n  flattenedSections {\n    path\n    title\n    id\n  }\n}\n\nfragment PolicyTable_dashboard on Dashboard {\n  key\n  title\n  description\n  documentation\n  flattenedSections {\n    id\n    hasPolicies\n    path\n    key\n    title\n    description\n    documentation\n    recommendedActions\n    resourceTypes {\n      unqualifiedName\n      id\n    }\n    severity\n    topSection {\n      id\n      title\n    }\n  }\n  providers\n}\n\nfragment Timeline_dashboard on Dashboard {\n  key\n  providers\n}\n\nfragment useEnabledDashboards_query_2g1q2o on Query {\n  dashboards(filterElement: $filterElement) {\n    edges {\n      node {\n        key\n        title\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f418d4474cf7e8a8f6aa4f608cd54794";

export default node;
