/**
 * @generated SignedSource<<57a362e47c6a8aa4a257cff77c0ea83d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewPage_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PageWithBreadCrumbs_query" | "useEnabledDashboards_query">;
  readonly " $fragmentType": "OverviewPage_query";
};
export type OverviewPage_query$key = {
  readonly " $data"?: OverviewPage_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewPage_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterElement"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewPage_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PageWithBreadCrumbs_query"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filterElement",
          "variableName": "filterElement"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useEnabledDashboards_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "5a3706b4ae2742d74591e19e693bdd79";

export default node;
