import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { COLORS, StatCard } from "@stacklet/ui";

import { Currency } from "app/components/Currency";
import { EOM_DATE_STR } from "app/utils/date";
import { percentChanged } from "app/utils/math";

import { PercentChangeMessage } from "../../PercentChange";

import type { Query } from "@cubejs-client/core";
import type { UseCubeQueryResult } from "@cubejs-client/react";
import type { $TSFixMe } from "App";

interface AnnualCostQueryResult {
  "DashboardSection.key": string;
  "ControlSignal.date": string;
  "ResourceCostSummaries.dailyUnblendedCost": string;
}

function buildQuery(
  dashboardKey: string,
  dateRange: string | [string, string],
  provider: string | null,
  sectionKey?: string,
): Query {
  return {
    measures: ["ResourceCostSummaries.dailyUnblendedCost"],
    dimensions: [
      "Dashboard.key",
      "Resources.provider",
      ...(sectionKey ? ["DashboardSection.key"] : []),
    ],
    timeDimensions: [
      {
        dimension: "ControlSignal.date",
        granularity: "day",
        dateRange,
      },
    ],
    filters: [
      {
        member: "Dashboard.key",
        operator: "equals",
        values: [dashboardKey],
      },
      ...(sectionKey
        ? [
            {
              member: "DashboardSection.key",
              operator: "equals",
              values: [sectionKey],
            },
          ]
        : []),
      ...(provider
        ? [
            {
              member: "Resources.provider",
              operator: "equals",
              values: [provider],
            },
          ]
        : ([] as $TSFixMe)),
    ],
    order: [["DashboardSection.key", "asc"]],
  };
}

function checkQueryError(query: UseCubeQueryResult<AnnualCostQueryResult>) {
  if (query.error) {
    Sentry.captureException(query.error);
    console.error(query.error.toString());
  }
}

function getAnnualCost(
  query: UseCubeQueryResult<AnnualCostQueryResult>,
): number | null {
  if (!query.resultSet) {
    return null;
  }
  return (
    query.resultSet
      .rawData()
      .map(
        (item) => Number(item["ResourceCostSummaries.dailyUnblendedCost"]) || 0,
      )
      .reduce((partialSum, a) => partialSum + a, 0) * 365
  );
}

interface Props {
  dashboardKey: string;
  provider?: string | null;
  sectionKey?: string;
  compact?: boolean;
}

export function AnnualCost({
  dashboardKey,
  provider = null,
  sectionKey,
  compact = false,
}: Props) {
  // We use "Yesterday" here b/c we might not have ControlSignal data for today yet
  const queryCurr = useCubeQuery<AnnualCostQueryResult>(
    buildQuery(dashboardKey, "Yesterday", provider, sectionKey),
  );
  const queryPrev = useCubeQuery<AnnualCostQueryResult>(
    buildQuery(
      dashboardKey,
      [EOM_DATE_STR, EOM_DATE_STR],
      provider,
      sectionKey,
    ),
  );

  if (queryCurr.isLoading || queryPrev.isLoading) {
    return (
      <Skeleton
        animation="wave"
        height="100px"
        role="progressbar"
        variant="rectangular"
      />
    );
  }

  checkQueryError(queryCurr);
  checkQueryError(queryPrev);

  const costCurr = getAnnualCost(queryCurr);
  const costPrev = getAnnualCost(queryPrev);

  if (!costCurr) {
    return <Typography>Annual cost data not available</Typography>;
  }

  const percent = percentChanged(costCurr, costPrev);

  return (
    <StatCard
      message={<PercentChangeMessage percent={percent} />}
      title="Annual cost of findings"
    >
      <Currency
        amount={costCurr}
        color={COLORS.navy.L30}
        compact={compact}
        decimals={false}
        fontSize="18px"
        fontWeight={700}
        lineHeight="27px"
      />
    </StatCard>
  );
}
