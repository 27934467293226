import Box from "@mui/material/Box";
import { Tooltip } from "@stacklet/ui";

type IconMap = Record<string, string>;

const ICON_SERVICE_MAP: IconMap = {
  aws: "AWS-Cloud-alt",
  "a2i-runtime.sagemaker": "Amazon-SageMaker",
  a4b: "Alexa-For-Business",
  "access-analyzer": "AWS-Identity-and-Access-Management_IAM",
  acm: "AWS-Certificate-Manager",
  "acm-pca": "AWS-Certificate-Authority",
  amplify: "AWS-Amplify",
  "api.detective": "Security-Identity-and-Compliance",
  "api.ecr": "Amazon-EC2-Container-Registry",
  "api.elastic-inference": "Amazon-Elastic-Inference",
  "api.mediatailor": "AWS-Elemental-MediaTailor",
  "api.pricing": "AWS-Cost-Explorer",
  "api.sagemaker": "Amazon-SageMaker",
  "api.tunneling.iot": "IoT_Generic",
  apigateway: "Amazon-API-Gateway",
  appconfig: "AWS-Systems-Manager",
  "application-autoscaling": "Amazon-Application-Auto-Scaling",
  applicationinsights: "Amazon-CloudWatch",
  appmesh: "AWS-App-Mesh",
  appstream2: "Amazon-Appstream-2_0",
  appsync: "AWS-AppSync",
  athena: "Amazon-Athena",
  autoscaling: "Amazon-EC2_Auto-Scaling",
  backup: "AWS-Backup",
  batch: "AWS-Batch",
  budgets: "AWS-Budgets",
  "catalog.marketplace": "AWS-Marketplace",
  ce: "AWS-Cost-Explorer",
  chime: "Amazon-Chime",
  cloud9: "AWS-Cloud9",
  clouddirectory: "Amazon-Cloud-Directory",
  cloudformation: "AWS-CloudFormation",
  cloudfront: "Amazon-CloudFront",
  cloudhsm: "AWS-CloudHSM",
  cloudhsmv2: "AWS-CloudHSM",
  cloudsearch: "Amazon-CloudSearch",
  cloudsearchdomain: "Amazon-CloudSearch",
  cloudtrail: "AWS-CloudTrail",
  codebuild: "AWS-CodeBuild",
  codecommit: "AWS-CodeCommit",
  codedeploy: "AWS-CodeDeploy",
  "codeguru-profiler": "Developer-Tools",
  "codeguru-reviewer": "Developer-Tools",
  codepipeline: "AWS-CodePipeline",
  codestar: "AWS-CodeStar",
  "codestar-connections": "AWS-CodeStar",
  "codestar-notifications": "AWS-CodeStar",
  "cognito-identity": "Amazon-Cognito",
  "cognito-idp": "Amazon-Cognito",
  "cognito-sync": "Amazon-Cognito",
  comprehend: "Amazon-Comprehend",
  comprehendmedical: "Amazon-Comprehend",
  "compute-optimizer": "Reserved-Instance-Reporting",
  config: "AWS-Config",
  connect: "Amazon-Connect",
  cur: "AWS-Cost-and-Usage-Report",
  "data.iot": "Internet-of-Things",
  "data.iotevents": "AWS-IoT-Events",
  "data.jobs.iot": "Internet-of-Things",
  "data.mediastore": "AWS-Elemental-MediaStore",
  dataexchange: "Amazon-Simple-Storage-Service-S3_Bucket-with-Objects",
  datapipeline: "AWS-Data-Pipeline",
  datasync: "AWS-DataSync",
  dax: "Amazon-DynamoDB_DAX",
  devicefarm: "AWS-Device-Farm",
  "devices.iot1click": "AWS-IoT-1-Click",
  directconnect: "AWS-Direct-Connect",
  discovery: "AWS-Migration-Hub",
  dlm: "AWS-Backup",
  dms: "AWS-Database-Migration-Service",
  ds: "AWS-Directory-Service",
  dynamodb: "Amazon-DynamoDB",
  ebs: "Amazon-Elastic-Block-Store-EBS",
  ec2: "Amazon-EC2",
  "ec2-instance-connect": "Amazon-EC2",
  ecr: "Amazon-EC2-Container-Registry",
  ecs: "Amazon-Elastic-Container-Service",
  eks: "Amazon-Elastic-Kubernetes-Service",
  elasticache: "Amazon-ElastiCache",
  elasticbeanstalk: "AWS-Elastic-Beanstalk",
  elasticfilesystem: "Amazon-Elastic-File-System_EFS",
  elasticloadbalancing: "Elastic-Load-Balancing",
  elasticmapreduce: "Amazon-EMR",
  elastictranscoder: "Amazon-Elastic-Transcoder",
  email: "Amazon-Simple-Email-Service-SES",
  "entitlement.marketplace": "AWS-Marketplace",
  es: "Amazon-Elasticsearch-Service",
  events: "Amazon-EventBridge",
  "execute-api": "Amazon-API-Gateway",
  firehose: "Amazon-Kinesis-Data-Firehose",
  fms: "AWS-Firewall-Manager",
  forecast: "Amazon-Forecast",
  forecastquery: "Amazon-Forecast",
  frauddetector: "Machine-Learning",
  fsx: "Amazon-FSx",
  gamelift: "Amazon-GameLift",
  glacier: "Amazon-S3-Glacier",
  globalaccelerator: "AWS-Global-Accelerator",
  glue: "AWS-Glue",
  greengrass: "AWS-IoT-Greengrass",
  groundstation: "AWS-Ground-Station",
  guardduty: "Amazon-GuardDuty",
  health: "AWS-Personal-Health-Dashboard",
  iam: "AWS-Identity-and-Access-Management_IAM",
  imagebuilder: "Amazon-EC2_AMI",
  importexport: "AWS-Snow-Family_Snowball-Import-Export",
  inspector: "Amazon-Inspector",
  iot: "Internet-of-Things",
  iotanalytics: "AWS-IoT-Analytics",
  iotevents: "AWS-IoT-Events",
  iotthingsgraph: "AWS-IoT-Things-Graph",
  kafka: "Amazon-Managed-Streaming-for-Kafka",
  kendra: "Machine-Learning",
  kinesis: "Amazon-Kinesis",
  kinesisanalytics: "Amazon-Kinesis-Data-Analytics",
  kinesisvideo: "Amazon-Kinesis-Video-Streams",
  kms: "AWS-Key-Management-Service",
  lakeformation: "AWS-Lake-Formation",
  lambda: "AWS-Lambda",
  "license-manager": "AWS-License-Manager",
  lightsail: "Amazon-Lightsail",
  logs: "Amazon-CloudWatch",
  machinelearning: "Machine-Learning",
  macie: "Amazon-Macie",
  managedblockchain: "Amazon-Managed-Blockchain",
  marketplacecommerceanalytics: "AWS-Marketplace",
  mediaconnect: "AWS-Elemental-MediaConnect",
  mediaconvert: "AWS-Elemental-MediaConvert",
  medialive: "AWS-Elemental-MediaLive",
  mediapackage: "AWS-Elemental-MediaPackage",
  "mediapackage-vod": "AWS-Elemental-MediaPackage",
  mediastore: "AWS-Elemental-MediaStore",
  "metering.marketplace": "AWS-Marketplace",
  mgh: "AWS-Migration-Hub",
  "migrationhub-config": "AWS-Migration-Hub",
  mobile: "Mobile",
  mobileanalytics: "Mobile",
  "models.lex": "Amazon-Lex",
  monitoring: "Amazon-CloudWatch",
  mq: "Amazon-MQ",
  "mturk-requester": "Users",
  networkmanager: "Amazon-VPC_Router",
  oidc: "AWS-Single-Sign-On",
  opsworks: "AWS-OpsWorks",
  "opsworks-cm": "AWS-OpsWorks",
  organizations: "AWS-Organizations",
  outposts: "Corporate-data-center",
  "participant.connect": "Amazon-Connect",
  personalize: "Amazon-Personalize",
  "personalize-events": "Amazon-Personalize",
  "personalize-runtime": "Amazon-Personalize",
  pi: "Amazon-CloudWatch",
  pinpoint: "Amazon-Pinpoint",
  polly: "Amazon-Polly",
  "portal.sso": "AWS-Single-Sign-On",
  "projects.iot1click": "AWS-IoT-Analytics_Notebook",
  qldb: "Amazon-Quantum-Ledger-Database_QLDB",
  quicksight: "Amazon-Quicksight",
  ram: "AWS-Resource-Access-Manager",
  rds: "Amazon-RDS",
  "rds-data": "Amazon-Aurora",
  redshift: "Amazon-Redshift",
  rekognition: "Amazon-Rekognition",
  "resource-groups": "AWS-Resource-Access-Manager",
  robomaker: "AWS-RoboMaker",
  route53: "Amazon-Route-53",
  route53domains: "Amazon-Route-53",
  route53resolver: "Amazon-Route-53",
  "runtime.lex": "Amazon-Lex",
  "runtime.sagemaker": "Amazon-SageMaker",
  s3: "Amazon-Simple-Storage-Service-S3",
  "s3-control": "Amazon-Simple-Storage-Service-S3",
  sagemaker: "Amazon-SageMaker",
  savingsplans: "AWS-Budgets",
  schemas: "Amazon-EventBridge",
  sdb: "Database",
  secretsmanager: "AWS-Secrets-Manager",
  securityhub: "AWS-Security-Hub",
  serverlessrepo: "AWS-Serverless-Application-Repository",
  servicecatalog: "AWS-Service-Catalog",
  servicediscovery: "Amazon-Route-53",
  servicequotas: "AWS-Trusted-Advisor",
  "session.qldb": "Amazon-Quantum-Ledger-Database_QLDB",
  shield: "AWS-Shield_Shield-Advanced",
  signer: "AWS-Identity-and-Access-Management-IAM_Data-Encryption-Key",
  sms: "Amazon-Pinpoint",
  "sms-voice.pinpoint": "Amazon-Pinpoint",
  snowball: "AWS-Snowball",
  sns: "Amazon-Simple-Notification-Service-SNS",
  sqs: "Amazon-Simple-Queue-Service-SQS",
  ssm: "AWS-Systems-Manager",
  states: "AWS-Step-Functions",
  storagegateway: "AWS-Storage-Gateway",
  "streams.dynamodb": "Amazon-DynamoDB",
  sts: "AWS-Identity-and-Access-Management-IAM_AWS-STS",
  support: "AWS-Trusted-Advisor",
  swf: "AWS-Step-Functions",
  tagging: "Application-Integration_Event_Resource",
  textract: "Amazon-Textract",
  transcribe: "Amazon-Transcribe",
  transfer: "AWS-Transfer-for-SFTP",
  translate: "Amazon-Translate",
  waf: "AWS-WAF",
  "waf-regional": "AWS-WAF",
  wafv2: "AWS-WAF",
  workdocs: "Amazon-WorkDocs",
  worklink: "Amazon-WorkLink",
  workmail: "Amazon-WorkMail",
  workmailmessageflow: "Amazon-WorkMail",
  workspaces: "Amazon-Workspaces",
  xray: "AWS-X-Ray",
};

interface Props {
  resource: string;
  height?: number;
  tooltip?: boolean;
}

export default function AwsResourcesIcon({
  resource,
  tooltip = true,
}: Props): JSX.Element | null {
  const resourceIdentifier = ICON_SERVICE_MAP[resource];

  if (!resourceIdentifier) {
    return null;
  }

  const className = `svg-${resourceIdentifier}`;

  return (
    <Box
      sx={{
        display: "inline-flex",
        px: 1,
      }}
    >
      {tooltip ? (
        <Tooltip data-testid="resource-tooltip" title={resource}>
          <Box className={`${className} ${className}-dims`} />
        </Tooltip>
      ) : (
        <Box className={`${className} ${className}-dims`} />
      )}
    </Box>
  );
}
