import { graphql, useFragment } from "react-relay";

import { Findings } from "./Findings";

import type { OverallFindings_dashboard$key } from "./__generated__/OverallFindings_dashboard.graphql";

interface Props {
  dashboard: OverallFindings_dashboard$key;
  enableMenu?: boolean;
}

export function OverallFindings({ dashboard, enableMenu = true }: Props) {
  const data = useFragment(
    graphql`
      fragment OverallFindings_dashboard on Dashboard {
        key
        providers
        title
      }
    `,
    dashboard,
  );

  const provider = data?.providers?.length
    ? data.providers[0].toLowerCase()
    : "";

  const description = `Count of findings across all ${data.title} sections`;

  return (
    <Findings
      dashboardKey={data.key}
      description={description}
      enableMenu={enableMenu}
      provider={provider}
      title="Findings"
    />
  );
}
