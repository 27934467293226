import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { graphql, useFragment } from "react-relay";

import { Link } from "app/components/links";

import type { SectionModalDetails_section$key } from "./__generated__/SectionModalDetails_section.graphql";

interface Props {
  dashboardSection: SectionModalDetails_section$key;
  onClose(): void;
}

export function SectionModalDetails({ dashboardSection, onClose }: Props) {
  const data = useFragment(
    graphql`
      fragment SectionModalDetails_section on DashboardSection {
        description
        key
        numeral
        title
        topSection {
          title
        }
      }
    `,
    dashboardSection,
  );

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ mb: 1 }} variant="h3">
            {data.title}
          </Typography>
          <IconButton onClick={onClose} sx={{ ml: "auto" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ mb: 2 }}>
          {data.key ? (
            <Link to={`details/${data.key}`}>Go to full recommendation</Link>
          ) : null}
        </Box>
        <Box>{data.description || ""}</Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box>
        <Typography sx={{ mb: 1 }} variant="h4">
          Details
        </Typography>
        {data.numeral ? (
          <>
            <Typography sx={{ textTransform: "uppercase" }}>
              Identifier
            </Typography>
            <Typography sx={{ mb: 1 }}>{data.numeral}</Typography>
          </>
        ) : null}
        <Typography sx={{ textTransform: "uppercase" }}>Section</Typography>
        <Typography>{data.topSection.title}</Typography>
      </Box>
    </Box>
  );
}
