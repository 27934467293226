import { type PropsWithChildren } from "react";

import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { PageWithBreadCrumbs } from "app/components/layout/PageWithBreadCrumbs";
import { useEnabledDashboards } from "app/hooks/useEnabledDashboards/useEnabledDashboards";

import type { OverviewPage_query$key } from "./__generated__/OverviewPage_query.graphql";

interface Props extends PropsWithChildren {
  title: string;
  queryRef: OverviewPage_query$key;
}

export function OverviewPage({ queryRef, title, children }: Props) {
  const data = useFragment(
    graphql`
      fragment OverviewPage_query on Query
      @argumentDefinitions(filterElement: { type: "FilterElementInput" }) {
        ...PageWithBreadCrumbs_query
        ...useEnabledDashboards_query @arguments(filterElement: $filterElement)
      }
    `,
    queryRef,
  );

  const dashboards = useEnabledDashboards(data);

  const breadcrumbs = [
    { label: "Overview" },
    {
      tabs: [
        { label: "Cloud", target: `/dashboards/cloud` },
        { label: "Stacklet Activity", target: "/activity" },
        ...dashboards,
        { label: "Deployed Policies", target: `/policies` },
      ],
    },
  ];

  return (
    <PageWithBreadCrumbs
      breadcrumbs={breadcrumbs}
      defaultTitle="Overview"
      queryRef={data}
      title={title}
    >
      {children}
    </PageWithBreadCrumbs>
  );
}
