/**
 * @generated SignedSource<<9d8e63caccad6bf34529b948c00f60d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type FilterBooleanOperator = "AND" | "NOT" | "OR" | "%future added value";
export type FilterElementInput = {
  multiple?: FilterBooleanOperationInput | null | undefined;
  single?: FilterValueInput | null | undefined;
};
export type FilterValueInput = {
  name: string;
  operator?: string | null | undefined;
  value?: string | null | undefined;
};
export type FilterBooleanOperationInput = {
  operands: ReadonlyArray<FilterElementInput>;
  operator: FilterBooleanOperator;
};
export type ExecutionDetailsRootQuery$variables = {
  filterElement?: FilterElementInput | null | undefined;
  uuid: string;
};
export type ExecutionDetailsRootQuery$data = {
  readonly execution: {
    readonly account: {
      readonly key: string;
      readonly provider: CloudProvider;
    };
    readonly end: string | null | undefined;
    readonly event: string | null | undefined;
    readonly issues: ReadonlyArray<string> | null | undefined;
    readonly metricApiCalls: number | null | undefined;
    readonly metricException: boolean | null | undefined;
    readonly metricResources: number | null | undefined;
    readonly paramDryrun: boolean | null | undefined;
    readonly paramRegion: string | null | undefined;
    readonly policy: {
      readonly name: string;
      readonly uuid: string;
      readonly version: number;
    };
    readonly start: string;
    readonly status: string;
    readonly uuid: string;
    readonly " $fragmentSpreads": FragmentRefs<"ExecutionLog_log">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewPage_query">;
};
export type ExecutionDetailsRootQuery = {
  response: ExecutionDetailsRootQuery$data;
  variables: ExecutionDetailsRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterElement"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterElement",
    "variableName": "filterElement"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paramRegion",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metricResources",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metricApiCalls",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metricException",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paramDryrun",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issues",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExecutionDetailsRootQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "OverviewPage_query"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PolicyExecution",
        "kind": "LinkedField",
        "name": "execution",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Policy",
            "kind": "LinkedField",
            "name": "policy",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v4/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExecutionLog_log"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExecutionDetailsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "whoami",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UrlConfig",
            "kind": "LinkedField",
            "name": "UrlConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DashboardConnection",
        "kind": "LinkedField",
        "name": "dashboards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DashboardEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dashboard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PolicyExecution",
        "kind": "LinkedField",
        "name": "execution",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Policy",
            "kind": "LinkedField",
            "name": "policy",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v4/*: any*/),
              (v15/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "log",
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "939d63e3a57844c23bf7108163f96142",
    "id": null,
    "metadata": {},
    "name": "ExecutionDetailsRootQuery",
    "operationKind": "query",
    "text": "query ExecutionDetailsRootQuery(\n  $filterElement: FilterElementInput\n  $uuid: String!\n) {\n  ...OverviewPage_query_2g1q2o\n  execution(uuid: $uuid) {\n    event\n    uuid\n    start\n    end\n    paramRegion\n    metricResources\n    metricApiCalls\n    metricException\n    paramDryrun\n    status\n    issues\n    policy {\n      name\n      uuid\n      version\n      id\n    }\n    account {\n      key\n      provider\n      id\n    }\n    ...ExecutionLog_log\n    id\n  }\n}\n\nfragment BreadCrumbsHeader_query on Query {\n  whoami {\n    email\n    displayName\n  }\n}\n\nfragment ExecutionLog_log on PolicyExecution {\n  log\n}\n\nfragment OverviewPage_query_2g1q2o on Query {\n  ...PageWithBreadCrumbs_query\n  ...useEnabledDashboards_query_2g1q2o\n}\n\nfragment PageWithBreadCrumbs_query on Query {\n  ...BreadCrumbsHeader_query\n}\n\nfragment useEnabledDashboards_query_2g1q2o on Query {\n  dashboards(filterElement: $filterElement) {\n    edges {\n      node {\n        key\n        title\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "57c739cd43671c8f7787f964ed16e64a";

export default node;
